<template>
  <div class="elv-login-form-container">
    <!-- form 表单 -->
    <el-form
      ref="loginFormRef"
      :label-position="'top'"
      label-width="100px"
      :model="loginForm"
      :rules="rules"
      :inline="true"
      :hide-required-asterisk="false"
      @submit.prevent
    >
      <!-- @submit.prevent="onSubmit" -->
      <!-- 邮箱 -->
      <template v-if="props.loginState === 1">
        <template v-if="!isSendCaptcha">
          <el-form-item :label="t('common.emailAddress')" prop="email" :class="{ 'is-errored': passwordError }">
            <el-input
              v-model.trim="loginForm.email"
              class="elv-login-input"
              clearable
              :placeholder="t('placeholder.enterEmail')"
              @input="onChangePassword"
            >
            </el-input>
          </el-form-item>
          <elv-button
            v-if="!isPasswordLogin"
            class="elv-login-continue"
            :disabled="loginForm.email === ''"
            @click="sendCaptcha"
          >
            {{ props.type === 'login' ? t('button.signIn') : t('button.signUp') }}
          </elv-button>
        </template>

        <template v-else>
          <p
            class="elv-login-form-captcha__info"
            v-html="t('message.pleaseEnterVerificationCode', { email: loginForm.email })"
          ></p>
          <!-- 验证码 -->
          <el-form-item v-if="!isPasswordLogin" prop="authCode" class="elv-login-form-captcha">
            <el-input
              v-for="(item, index) in code"
              :key="index"
              :ref="inputRefs[index]"
              v-model="code[index]"
              type="tel"
              maxlength="1"
              class="code-input"
              @input="onInputChange(index, $event)"
              @keydown="onKeyDown(index, $event)"
              @keyup.enter="onLogin"
            />
          </el-form-item>
          <div class="elv-login-form-captcha__button">
            <span
              :class="{ 'elv-login-form-captcha__button-disabled': verificationCodeOptions.timer }"
              @click="sendCaptcha"
              >{{ verificationCodeOptions.text }}</span
            >
            <span @click="onChangeEmail">{{ t('button.changeEmail') }}</span>
          </div>
        </template>

        <!-- 密码 -->
        <el-form-item v-if="isPasswordLogin" :label="t('common.password')" prop="password">
          <el-input
            v-model="loginForm.password"
            class="elv-login-input"
            :placeholder="t('placeholder.enterPassword')"
            type="password"
            show-password
            @input="onChangePassword"
            @keyup.enter="onLogin"
          />
        </el-form-item>
      </template>

      <template v-if="props.loginState === 2">
        <el-form-item :label="t('input.nameLabel')" prop="name">
          <el-input v-model="loginForm.name" class="elv-login-input" :placeholder="t('placeholder.enterName')" />
        </el-form-item>
        <el-form-item
          :label="props.type === 'login' ? t('input.passwordsLabel') : t('input.setPasswordsLabel')"
          prop="password"
        >
          <el-input
            v-model="loginForm.password"
            class="elv-login-input"
            type="password"
            show-password
            :placeholder="t('placeholder.enterPassword')"
          />
        </el-form-item>
      </template>

      <el-form-item class="elv-form-item-operating">
        <div class="elv-login-button-box">
          <elv-button
            v-if="props.loginState === 1 && (isSendCaptcha || isPasswordLogin)"
            color="#1753EB"
            style="color: #ffffff"
            class="elv-login-button"
            :disabled="
              (isSendCaptcha && String(loginForm.authCode)?.length < 6) ||
              (isPasswordLogin && (!loginForm.password || !loginForm.email))
            "
            :loading="loginForm.isLoading"
            @click="onLogin"
          >
            <span v-if="!loginForm.isLoading">{{
              isSendCaptcha ? t('common.continue') : props.type === 'login' ? t('button.signIn') : t('button.signUp')
            }}</span>
          </elv-button>
          <div v-if="props.loginState === 1" class="elv-login-button__checkout">
            <span>{{ t('common.or') }}</span>
          </div>
          <GoogleSign v-if="props.loginState === 1 && !isPasswordLogin" @onCompleteRegister="onCompleteRegister" />
          <el-button
            v-if="props.loginState === 1 && isPasswordLogin && props.type === 'login'"
            class="elv-login-button--plain"
            @click="changeLoginType"
          >
            <SvgIcon name="continue-verification-code" /> {{ t('common.signInWithVerificationCode') }}
          </el-button>
          <el-button
            v-if="props.loginState === 1 && !isPasswordLogin && props.type === 'login'"
            class="elv-login-button--plain"
            @click="changeLoginType"
          >
            <SvgIcon name="continue-password" /> {{ t('common.signInWithPassword') }}
          </el-button>

          <elv-button
            v-if="props.loginState === 2"
            color="#1753EB"
            style="color: #ffffff"
            class="elv-login-button elv-signup-button"
            :loading="loginForm.isLoading"
            :disabled="!loginForm.name || !loginForm.password"
            @click="onRegister"
          >
            {{ t('button.signUp') }}</elv-button
          >
        </div>
      </el-form-item>

      <!-- <p class="elv-login-method" @click="onChangeOtherWaysLogin">{{ t('button.otherSign') }}</p> -->
    </el-form>
  </div>
</template>

<script lang="ts" setup>
import utils from '@/lib/utils'
import { useI18n } from 'vue-i18n'
import UserApi from '@/api/UserApi'
import { ElMessage } from 'element-plus'
import { useCookies } from 'vue3-cookies'
import GoogleSign from './GoogleSign.vue'
import type { FormInstance, FormRules } from 'element-plus'
import { useGlobalStore } from '@/stores/modules/global'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { RegisterParamsType, CompleteRegisterParamsType } from '#/UserTypes'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const { cookies } = useCookies()
const globalStore = useGlobalStore()
const userGlobalStore = useUserGlobalStore()

const isPasswordLogin = ref(false)
const isSendCaptcha = ref(false)
const captchaError = ref(false)
const passwordError = ref(false)
const registerState = ref('register')
const loginFormRef = ref<FormInstance>()
// eslint-disable-next-line no-unused-vars
const inputRefs: any = Array.from({ length: 6 }, (_, i) => ref(null))

const emit = defineEmits(['changeLoginState', 'onContinueWithEmail'])
const props = defineProps({
  // 下拉高度
  loginState: {
    type: Number,
    default: 1
  },
  isDialog: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'login'
  }
})
// 表单变量
interface VerificationCodeType {
  text: string
  duration: number
  disabled: boolean
  isLoading: boolean
  timer: any
}
interface loginFormType {
  email: string
  authCode: string | number
  password: string
  name: string
  showIcon: boolean
  isLoading: boolean
}

const verificationCodeOptions = reactive<VerificationCodeType>({
  text: t('button.sentCaptchaButtonText'),
  duration: 60,
  isLoading: false,
  disabled: false,
  timer: null
})

const loginForm: loginFormType = reactive({
  email: '',
  authCode: '',
  password: '',
  name: '',
  showIcon: true,
  isLoading: false
})

const code = ref(['', '', '', '', '', ''])
const currentIndex = ref(0)

// 表单规则定义
const rules = reactive<FormRules>({
  email: [
    {
      required: true,
      trigger: 'blur',
      validator(rule: any, value: string) {
        if (!value) {
          return new Error(`${t('message.pleaseInputEmail')}.`)
        }
        // eslint-disable-next-line no-useless-escape
        if (!/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,})+$/.test(value)) {
          return new Error(`${t('message.loginEmailPlaceholder')}.`)
        }
        return true
      }
    }
  ],
  authCode: [
    {
      required: true,
      validator(rule: any, value: string) {
        if (captchaError.value && value) {
          return new Error(`${t('message.captchaError')}`)
        }
        if (!value) {
          return new Error(`${t('message.captchaWarning')}`)
        }
        return true
      },
      trigger: 'blur'
    }
  ],
  password: [
    {
      required: true,
      validator(rule: any, value: string) {
        if (passwordError.value && value) {
          return new Error(`${t('message.emailError')}`)
        }
        if (!value) {
          return new Error(`${t('message.pleaseInputPassword')}`)
        }
        return true
      },
      trigger: 'blur'
    }
  ],
  name: [
    {
      required: true,
      message: t('message.pleaseInputName'),
      trigger: 'blur'
    }
  ]
})
const isInvalid = ref(false)

// eslint-disable-next-line no-unused-vars
const isShowService = computed(() => {
  return props.loginState === 1 && ((!isPasswordLogin.value && !isSendCaptcha.value) || isPasswordLogin.value)
})

const bottomDistance = computed(() => {
  return props.isDialog ? '20px' : '99.5px'
})

// eslint-disable-next-line no-unused-vars
const onChangeOtherWaysLogin = () => {
  emit('onContinueWithEmail')
}
const onChangeEmail = () => {
  isSendCaptcha.value = false
  // eslint-disable-next-line no-unused-expressions
  verificationCodeOptions.timer && clearInterval(verificationCodeOptions.timer)
  verificationCodeOptions.timer = null
}

const onChangePassword = () => {
  passwordError.value = false
}
const onInputChange = (index: any, event: any) => {
  if (event) {
    currentIndex.value = Math.min(index + 1, 5)
    const nextInput: any = inputRefs[currentIndex.value]
    if (nextInput && nextInput.value) {
      nextTick(() => {
        nextInput.value[0]?.focus()
      })
    }
  } else {
    currentIndex.value = Math.max(index - 1, 0)
  }
  captchaError.value = false
  isInvalid.value = false
}

// eslint-disable-next-line no-unused-vars
const onCompleteRegister = () => {
  emit('changeLoginState', 2)
  registerState.value = 'completeRegister'
}

const onKeyDown = (index: any, event: any) => {
  if (event.key === 'Backspace') {
    // event.preventDefault()
    code.value[index] = ''
    currentIndex.value = Math.max(index - 1, 0)
    const prevInput: any = inputRefs[currentIndex.value]
    if (prevInput) {
      nextTick(() => {
        prevInput.value[0].focus()
      })
    }
  }
}

// 改变验证码按钮方法
const timer = () => {
  verificationCodeOptions.disabled = true
  // 倒计时方法
  // 清除掉定时器
  // eslint-disable-next-line no-unused-expressions
  verificationCodeOptions.timer && clearInterval(verificationCodeOptions.timer)
  // 开启定时器
  verificationCodeOptions.timer = setInterval(() => {
    // eslint-disable-next-line no-plusplus
    const tmp = verificationCodeOptions.duration--
    verificationCodeOptions.text = `${t('message.resendAfter', { tmp })}`

    if (tmp <= 0) {
      // 清除掉定时器
      clearInterval(verificationCodeOptions.timer)
      verificationCodeOptions.timer = null
      verificationCodeOptions.duration = 60
      verificationCodeOptions.text = `${t('button.sentCaptchaButtonText')}`
      // 设置按钮可以单击
      verificationCodeOptions.disabled = false
    }
  }, 1000)
}

// 获取验证码方法
// eslint-disable-next-line no-unused-vars
const sendCaptcha = () => {
  // 此处只校验 是否填写邮箱
  loginFormRef.value?.validateField('email', async (isValidate) => {
    if (isValidate) {
      try {
        isSendCaptcha.value = true
        verificationCodeOptions.isLoading = true
        nextTick(() => {
          inputRefs[0].value?.[0]?.focus()
        })
        await UserApi.sendCaptcha(loginForm.email)
        timer()
        verificationCodeOptions.isLoading = false
      } catch (errors: any) {
        if (errors.code === 10003) {
          ElMessage.warning(utils.formatLanguageContent(errors.message))
        } else {
          ElMessage.error(utils.formatLanguageContent(errors.message))
        }
      }
    }
  })
}

// 登录
const onLogin = async () => {
  loginFormRef.value?.validate(async (isValidate) => {
    if (isValidate) {
      try {
        if (!isPasswordLogin.value && String(loginForm.authCode).length < 6) return
        loginForm.isLoading = true
        const { data } = await UserApi.userLogin(loginForm.email, Number(loginForm.authCode), loginForm.password)
        // 登录成功
        cookies.set('elv-app-token', data.token, '7d', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN, true)
        await userGlobalStore.userInit()
        if (!userGlobalStore.user?.name) {
          emit('changeLoginState', 2)
          registerState.value = 'completeRegister'
        } else {
          await globalStore.fetchProjectList()
          ElMessage.success(t('message.signInSuccess'))
          router.push('/')
          userGlobalStore.closeLogin()
        }
        // 跳转页面
      } catch (error: any) {
        if (error.code === 10011) {
          isSendCaptcha.value = false
          // 未设置密码
          emit('changeLoginState', 2)
        } else if (error.code === 10007) {
          // 验证码错误
          captchaError.value = true
          loginFormRef.value?.validateField('authCode')
        } else if (error.code === 10010) {
          // 密码错误
          passwordError.value = true
          loginFormRef.value?.validateField(['email', 'password'])
        } else {
          ElMessage.error(utils.formatLanguageContent(error.message))
        }
      }
    }
    loginForm.isLoading = false
    // return true
  })
}
// 注册
const onRegister = async () => {
  loginFormRef.value?.validate(async (isValidate) => {
    if (isValidate) {
      try {
        loginForm.isLoading = true
        const history = document.referrer
        if (registerState.value === 'register') {
          const params: RegisterParamsType = {
            email: loginForm.email,
            name: loginForm.name,
            password: loginForm.password,
            code: Number(loginForm.authCode)
          }
          if (route.query?.ref || route.query?.referer) {
            params.referer = route.query?.ref ? String(route.query?.ref) : String(route.query?.referer)
          }
          const { data } = await UserApi.userRegister(params, history)
          // 注册成功
          cookies.set('elv-app-token', data.token, '7d', '/', import.meta.env.VITE_APP_COOKIES_DOMAIN, true)
        } else {
          const params: CompleteRegisterParamsType = {
            name: loginForm.name,
            password: loginForm.password
          }
          if (route.query?.ref || route.query?.referer) {
            params.referer = route.query?.ref ? String(route.query?.ref) : String(route.query?.referer)
          }
          // 完善注册
          await UserApi.userCompleteRegister(params, history)
        }
        await userGlobalStore.userInit()
        await globalStore.fetchProjectList()
        ElMessage.success(t('message.signUpSuccess'))
        // 跳转页面
        router.push('/')
        userGlobalStore.closeLogin()
      } catch (error: any) {
        loginForm.isLoading = false
        ElMessage.error(utils.formatLanguageContent(error.message))
      }
    }
  })
  return true
}

// 改变登录类型
const changeLoginType = () => {
  loginForm.authCode = ''
  loginForm.password = ''
  isPasswordLogin.value = !isPasswordLogin.value
  isSendCaptcha.value = false
}

const getClipboardContent = (event: any) => {
  // eslint-disable-next-line no-param-reassign
  event = event || window.event
  const clipdata = event.clipboardData || window.ClipboardData
  const value = clipdata.getData('text/plain')
  if (props.loginState === 1 && isSendCaptcha.value) {
    if (value.length === 6 && code.value.join('') === '') {
      code.value = value.split('')
      loginForm.authCode = parseInt(code.value.join(''), 10) ? parseInt(code.value.join(''), 10) : ''
      inputRefs[5].value?.[0]?.focus()
    }
  }
}

// eslint-disable-next-line no-unused-vars
const onSubmit = () => {
  // 登录
  if (props.loginState === 1) {
    if (!isSendCaptcha.value && !isPasswordLogin.value) {
      // 发送验证
      sendCaptcha()
    } else if (isSendCaptcha.value && !isPasswordLogin.value) {
      // 登录
      loginFormRef.value?.validateField('authCode', async (isValidate) => {
        if (isValidate) {
          await onLogin()
        }
      })
    } else if (isPasswordLogin.value) {
      console.log(8888, '123123')
    }
  } else {
    // 注册
    console.log('注册12311')
  }
}

watchEffect(() => {
  // eslint-disable-next-line radix
  loginForm.authCode = parseInt(code.value.join('')) ? parseInt(code.value.join('')) : ''
})

onMounted(() => {
  if (route.name === 'login' && route.query?.state === '2') {
    registerState.value = 'completeRegister'
  }
  window.addEventListener('paste', getClipboardContent)
})

onBeforeUnmount(() => {
  window.removeEventListener('paste', getClipboardContent)
})

defineExpose({ isShowService, isSendCaptcha })
</script>

<style scoped lang="scss">
.elv-login-form-container {
  margin: 0;
  padding: 0;

  .elv-login-continue {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 315px;
    width: 100%;
    height: 45px;
    border-radius: 4px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    background: #1753eb;
    color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &.is-disabled {
      background: #edf0f3 !important;
      border: 1px solid #d0d4d9 !important;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05) !important;
      color: #aaafb6 !important;
      cursor: not-allowed;
    }
  }

  .elv-login-method {
    width: 136px;
    height: 18px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #838d95;
    cursor: pointer;
    margin: 0 auto;
    margin-top: v-bind('bottomDistance');
  }
}

.elv-login-button-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .elv-login-button__checkout {
    min-width: 315px;
    width: 100%;
    height: 1px;
    background-color: #edf0f3 !important;
    position: relative;
    margin: 22px 0;

    span {
      min-width: 19px;
      max-width: 24px;
      height: 14px;
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #dde1e6;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .elv-login-button {
    min-width: 315px;
    width: 100%;
    height: 45px;
    margin-top: 15px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    font-size: 14px;
    background: #1753eb;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: #ffffff;

    &.elv-signup-button {
      margin-top: 0px;
    }

    &.is-disabled {
      border: 1px solid #d0d4d9;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    }
  }

  .elv-login-button--plain {
    min-width: 315px;
    width: 100%;
    height: 45px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 13px;
    margin-left: 0;
    color: #1e2024;
    background: #ffffff;
    border: 1px solid #d0d4d9;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    transition: background-color 0.218s, border-color 0.218s;

    svg {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }

    &:hover {
      box-shadow: none;
      border-color: #d2e3fc;
      outline: none;
      background: rgba(66, 133, 244, 0.04);
    }
  }
}

.elv-login-input {
  height: 44px;
  min-width: 315px;
  width: 100%;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;
  caret-color: #2a56e2;
}

.elv-send-button {
  // width: 36px;
  height: 14px;
  color: #0e1420;

  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 14px;

  &:hover {
    color: #2d62eb;
    background-color: #ffffff !important;
  }
}
</style>
<style lang="scss">
.elv-login-form-container {
  .el-form.el-form--default {
    .el-form-item {
      width: 100%;
      margin-bottom: 15px;
      margin-right: 0px;
    }

    .el-form-item__label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      color: #1e2024;
      margin-bottom: 10px;

      &::before {
        // 隐藏掉必填的小红星
        display: none;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #0e0f11;
    }
    /*修改提示字的颜色*/
    input::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #aaafb6;
    }

    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #aaafb6;
    }

    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #aaafb6;
    }

    input:-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #aaafb6;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner {
      --el-checkbox-checked-bg-color: #1753eb;
      --el-checkbox-checked-input-border-color: #1753eb;
    }

    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #999ba0;
    }

    .el-input__wrapper:hover {
      box-shadow: 0 0 0 1px #2a56e2 inset;
    }

    .el-input__wrapper.is-focus {
      box-shadow: 0 0 0 1px #2a56e2 inset;
    }

    .el-form-item.is-error.elv-login-form-captcha {
      margin-bottom: 54px;
    }

    .el-form-item.is-error .el-input__wrapper {
      box-shadow: 0 0 0 1px #7e4a15 inset;
      background: #faeee6;
      color: #7e4a15;
    }

    .el-form-item.is-errored .el-input__wrapper {
      box-shadow: 0 0 0 1px #7e4a15 inset;
      background: #faeee6;
      color: #7e4a15;
    }

    .el-form-item.is-errored {
      margin-bottom: 0px;
    }

    .is-error,
    .is-errored {
      margin-bottom: 39px;

      input::-webkit-input-placeholder {
        // WebKit, Blink, Edge浏览器，带input，双冒号
        color: #7e4a15;
      }

      input::-moz-placeholder {
        // 火狐浏览器高版本（19+），不用带input，双冒号
        color: #7e4a15;
      }

      input:-moz-placeholder {
        // 火狐浏览器底版本（4 to 18），不用带input，单冒号
        color: #7e4a15;
      }

      input:-ms-input-placeholder {
        // IE浏览器底版本（10-11），带input，单冒号
        color: #7e4a15;
      }
    }

    .el-form-item__error {
      color: #7e4a15;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      margin-top: 10px;
    }

    .elv-login-form-captcha__info {
      min-width: 315px;
      width: 100%;
      height: 40px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #1e2024;
      margin: 0;
      margin-bottom: 15px;

      span {
        font-weight: 700;
      }
    }

    .elv-login-form-captcha {
      display: flex;

      .el-input {
        width: 44px;
        height: 45px;
        background: #ffffff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        margin-left: 10px;

        &:first-of-type {
          margin-left: 0px;
        }
      }

      .el-input__wrapper {
        padding: 0 8px;
        width: 100%;
        height: 100%;
      }

      .el-input__inner {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 14px;
        color: #0e0f11;
        text-align: center;
      }
    }

    .elv-login-form-captcha__button {
      min-width: 315px;
      width: 100%;
      height: 15px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #1343bf;
      display: flex;
      justify-content: space-between;

      span {
        cursor: pointer;
      }
    }

    .elv-login-form-captcha__button-disabled {
      color: #636b75;
      cursor: auto !important;
    }
  }
}
</style>
